<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card :elevation="1">
                    <v-card-text class="py-1">
                        <v-row dense>
                            <v-col cols="12" md="2">
                                <v-combobox
                                label="Month"
                                dense
                                outlined
                                hide-details
                                :items="months"
                                :loading="month_loading"
                                v-model="selectedMonth"
                                @focus="getMonths"
                                item-text="name"
                                item-value="id"
                                class="mb-1 custom-form"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox
                                label="Department"
                                dense
                                outlined
                                hide-details
                                :items="$store.getters['department/departments']"
                                v-model="department"
                                item-text="name"
                                item-value="id"
                                :loading="$store.getters['department/loadingDepartments']"
                                @focus="$store.dispatch('department/getDepartments')"
                                class="custom-form"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox
                                label="Designation"
                                dense
                                outlined
                                hide-details
                                :items="$store.getters['designation/designations']"
                                v-model="designation"
                                item-text="name"
                                item-value="id"
                                :loading="$store.getters['designation/loadingDesignations']"
                                @focus="$store.dispatch('designation/getDesignations')"
                                class="custom-form"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox
                                label="Shift"
                                dense
                                outlined
                                hide-details
                                :items="$store.getters['shift/shifts']"
                                v-model="shift"
                                item-text="name"
                                item-value="id"
                                :loading="$store.getters['shift/loadingShifts']"
                                @focus="$store.dispatch('shift/getShifts')"
                                class="custom-form"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox
                                label="Employee"
                                dense
                                outlined
                                hide-details
                                v-model="employee"
                                :items="$store.getters['employee/employees']"
                                :loading="$store.getters['employee/loadingEmployees']"
                                item-text="display_name"
                                item-value="id"
                                class="mb-1 custom-form"
                                @focus="getEmployees"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-btn
                                    elevation="1"
                                    small
                                    color="primary"
                                    dark
                                    @click="getPaySlips"
                                    :loading="loading"
                                    style="margin-top:-2px"
                                > Search </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="records.length > 0">
            <v-col cols="12">
                <v-btn color="primary" class="white--text" small dense @click="print">
                <v-icon left small dense> mdi-printer </v-icon>
                Print
                </v-btn>
            </v-col>
        </v-row>
        <v-row id="reportContent" v-if="records.length > 0">
            <v-col cols="6" v-for="(salary, sl) in records" :key="sl" class="pay-slip">
                <v-row>
                    <v-col cols="12" class="slip-top" style="text-align: center;text-decoration: underline;">
                        {{$store.getters["company/company"].name}}
                    </v-col>
                    <v-col cols="4" class="slip-top">
                        Pay Slip
                    </v-col>
                    <v-col cols="8" class="slip-top" style="text-align: right;">
                        {{salary.month_name}}
                    </v-col>
                </v-row>
                <v-simple-table dense class="pay_slip_table" style="border: 1px solid #000 !important;">
                    <tr>
                        <th colspan="4" style="text-align:left;border-bottom: 1px solid #000 !important;border-right: 1px solid #000 !important;">
                            E-ID: {{salary.emp_id}}<br>
                            Name: {{salary.employee_name}} <br>
                            Department: {{salary.department_name}}
                        </th>
                        <td colspan="2" style="text-align:left;border-bottom: 1px solid #000 !important;">
                            Designation: {{salary.designation_name}}<br>
                            Joining Date: {{salary.joining_date | dateFormat}}
                        </td>
                    </tr>

                    <tr>
                        <th colspan="2" style="border-bottom: 1px solid #000 !important;border-right: 1px solid #000 !important;text-align: center;">Salary</th>
                        <th colspan="2" style="border-bottom: 1px solid #000 !important;border-right: 1px solid #000 !important;text-align: center;">Attendance</th>
                        <th colspan="2" style="border-bottom: 1px solid #000 !important;text-align: center;">OT & Deduction</th>
                    </tr>

                    <tr class="data-tr">
                        <td>Basic</td>
                        <td>{{salary.basic_salary}}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr class="data-tr">
                        <td>House Rent</td>
                        <td>{{salary.house_rent_amount}}</td>
                        <td>Present</td>
                        <td>{{salary.present}}</td>
                        <td>OT Hours</td>
                        <td>{{salary.ot_hour}}</td>
                    </tr>
                    <tr class="data-tr">
                        <td>Medical</td>
                        <td>{{salary.medical_allowance_amount}}</td>
                        <td>Absent</td>
                        <td>{{ +salary.absent + +salary.late_absent }}</td>
                        <td>OT Rate</td>
                        <td>{{salary.ot_rate}}</td>
                    </tr>
                    <tr class="data-tr">
                        <td>Conveyance</td>
                        <td>{{salary.conveyance_allowance_amount}}</td>
                        <td>Leave</td>
                        <td>{{salary.leave}}</td>
                        <th style="text-align: center;">Deduction</th>
                        <td></td>
                    </tr>
                    <tr class="data-tr">
                        <td>Others</td>
                        <td>{{salary.others_allowance_amount}}</td>
                        <td>LWP</td>
                        <td>{{salary.leave_without_pay}}</td>
                        <td>Deduction</td>
                        <td>{{salary.deduction}}</td>
                    </tr>
                    <tr class="data-tr">
                        <td>Gross Salary</td>
                        <td>{{salary.gross_salary}}</td>
                        <td>Holiday</td>
                        <td>{{salary.holidays}}</td>
                        <td>Advance</td>
                        <td>{{salary.advance}}</td>
                    </tr>
                    <tr class="data-tr">
                        <td>Over Time</td>
                        <td>{{salary.ot_amount}}</td>
                        <td>Weekend</td>
                        <td>{{salary.weekends}}</td>
                        <th style="text-align: center;">Total Deduction</th>
                        <td>{{ +salary.deduction + +salary.advance }}</td>
                    </tr>
                    <tr class="data-tr">
                        <td style="border-bottom: 1px solid #000 !important;">Benefit</td>
                        <td style="border-bottom: 1px solid #000 !important;">{{salary.benefit}}</td>
                        <td style="border-bottom: 1px solid #000 !important;"></td>
                        <td style="border-bottom: 1px solid #000 !important;"></td>
                        <td style="border-bottom: 1px solid #000 !important;"></td>
                        <td style="border-bottom: 1px solid #000 !important;"></td>
                    </tr>
                    <tr>
                        <th style="border-right: 1px solid #000 !important;">Net Payable</th>
                        <th style="border-right: 1px solid #000 !important;">{{salary.net_payable}}</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </v-simple-table>
                <p style="text-align: right;font-size: 12px;margin-right: 10%;"><strong>Signature</strong></p>
            </v-col>
      </v-row>
    </v-container>
</template>

<script>
    import utility from '../../mixins/utility.mixin';
    export default {
        mixins: [utility],
        data() {
            return {
                department   : null,
                designation  : null,
                shift        : null,
                employee     : null,
                months       : [],
                selectedMonth: null,
                records      : [],
                month_loading: false,
                loading      : false,
            }
        },
        methods: {
            getEmployees() {
                this.employee = null;
                this.$store.dispatch("employee/getEmployees", {
                    apiParams: {
                    departmentId : this.department?.id ?? null,
                    designationId: this.designation?.id ?? null,
                    shiftId      : this.shift?.id ?? null,
                    },
                });
            },
            async getMonths(){
                this.month_loading = true;
                this.selectedMonth = null;
                this.months =  await this.$store.dispatch('month/getSalaryMonths', {
                    apiParams: {},
                    options: {
                        returnData: true
                    }
                });
                this.month_loading = false;
            },
            async getPaySlips(){
                this.records = [];
                this.loading = true;

                if(this.selectedMonth == null || this.selectedMonth.id == undefined){
                    alert('Select Month');
                    this.loading = false;
                    return;
                }
                this.records = await this.$store.dispatch("salary/getPaySlips",{
                    apiParams: {
                        departmentId : this.department?.id ?? null,
                        designationId: this.designation?.id ?? null,
                        shiftId      : this.shift?.id ?? null,
                        employeeId   : this.employee?.id ?? null,
                        monthId     : this.selectedMonth?.id ?? null,
                    },
                    options: {
                        returnData: true,
                    },
                    }
                );

                this.loading = false;
            },
            async print(){


                // Get HTML to print from element
                const prtHtml = document.getElementById('reportContent').innerHTML;

                // Get all stylesheets HTML
                let stylesHtml = '';
                for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                    stylesHtml += node.outerHTML;
                }

                // Open the print window
                // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}
                </head>
                <body>
                    <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                        <div class="container container--fluid">
                            <div class="row row--dense">
                                <div class="col col-12 px-2" style="display: flex; flex-wrap: wrap;">
                                    ${prtHtml}
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
                </html>`);

                WinPrint.document.head.innerHTML += `
                    <style>
                    .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row){
                        border-bottom: unset !important;
                    }
                    .data-tr td:not(:last-child), .data-tr th:not(:last-child){
                        border-right: 1px solid #000 !important;
                    }

                    .pay_slip_table th, .pay_slip_table td{
                        padding: 0 2px !important;
                        font-size: 11px !important;
                    }
                    tr:hover{
                        background: unset !important;
                    }
                    th, td{
                        height: 0 !important;
                    }
                    @media print{
                        .pay-slip{
                            height: 33.33vh !important;
                        }
                    }
                    </style>

                `;

                WinPrint.focus();
                WinPrint.document.close();
                await new Promise(resolve => setTimeout(resolve, 2000));
                WinPrint.print();
                WinPrint.close();
            }
        },
    }
</script>

<style scoped>
.data-tr td:not(:last-child), .data-tr th:not(:last-child){
    border-right: 1px solid #000 !important;
}


.pay_slip_table th, .pay_slip_table td{
    padding: 0 2px !important;
    font-size: 11px !important;
}
.pay-slip{
    height: 300px;
}
.slip-top{
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
}

</style>